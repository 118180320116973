import formulario from "./Formulario/Formulario.vue";

export default {
    name: "admin-formulario-conteudo",
    components: {
        formulario
    },
    data() {
        return {
            tab: 0,
            blLoading: true,
            obConteudo: null
        };
    },
    created() {
        if (this.$route.params.id) {
            this.obter(this.$route.params.id);
        } else {
            this.blLoading = false;
        }
    },
    methods: {
        obter(id) {
            this.$root.$api.get("conteudo/obter/" + id).then(response => {
                this.obConteudo = response.retorno;

                this.blLoading = false;
            });
        },
        atualizarConteudo(obConteudo) {
            this.blLoading = true;
            this.obConteudo = obConteudo;
            this.blLoading = false;
        }
    }
};
