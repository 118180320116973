import SelectAssuntoTema from "@/components/Admin/SelectAssuntoTema/SelectAssuntoTema.vue";
import Tinymce from "vue-tinymce-editor";
import { required, min, max } from "vuelidate/lib/validators";

export default {
    props: ["obConteudo", "blRascunho"],
    components: {
        SelectAssuntoTema,
        Tinymce
    },
    data() {
        return {
            blDialogExcluir: false,
            blExcluindo: false,
            blErro: false,
            blSalvando: false,
            blSalvandoRascunho: false,
            obForm: {
                id: 0,
                id_assunto: null,
                id_tema: null,
                titulo: "",
                subtitulo: "",
                conteudo: "",
                prioridade: 0,
                rascunho: false,
                id_conteudo: null
            },
            obOptions: {
                language_url:
                    process.env.VUE_APP_ROOT_API + "../langs-tinymce/pt_BR.js",
                //images_upload_url: process.env.VUE_APP_ROOT_API + "post/uploadImagem",
                convert_urls: false
            }
        };
    },
    validations: {
        obForm: {
            id_assunto: {
                required
            },
            id_tema: {
                required
            },
            titulo: {
                required
            },
            conteudo: {
                required
            },
            prioridade: {
                required
                /*min: min(0),
                max: max(99)*/
            }
        }
    },
    created() {
        this.preencherFormulario();
    },
    watch: {
        obConteudo() {
            this.preencherFormulario();
        }
    },
    methods: {
        editorInit() {
            if (this.$props.obConteudo)
                this.$refs.refTinymce.editor.setContent(
                    this.$props.obConteudo.conteudo
                );
        },
        preencherFormulario() {
            if (this.$props.obConteudo) {
                this.$root.$utils.preencheFormularios(
                    this.obForm,
                    this.$props.obConteudo
                );
            }
        },
        mensagemErro(strCampo) {
            return this.$utils.handleErrors(strCampo, this);
        },
        validarFormulario() {
            this.blErro = false;

            this.$v.obForm.$touch();
            this.$refs.refSelectAssuntoTema.$v.obForm.$touch();

            if (
                this.$v.obForm.$invalid ||
                this.$refs.refSelectAssuntoTema.$v.obForm.$invalid
            ) {
                this.blErro = true;
            }
        },
        salvarRascunho() {
            this.validarFormulario();

            if (!this.blErro) {
                this.blSalvandoRascunho = true;

                if (this.obForm.id != 0 && !this.$props.blRascunho) {
                    this.obForm.id_conteudo = this.obForm.id;
                    this.obForm.id = 0;
                }

                this.obForm.rascunho = true;
                this.submit();
            }
        },
        publicar() {
            this.validarFormulario();

            if (!this.blErro) {
                this.blSalvando = true;
                this.obForm.rascunho = false;

                this.submit();
            }
        },
        submit() {
            this.$root.$api
                .post("conteudo/salvar", this.obForm)
                .then(response => {
                    this.blSalvando = false;
                    this.blSalvandoRascunho = false;

                    if (!this.$route.params.id) {
                        this.$router.push({
                            name: "admin-conteudo-cadastro",
                            params: {
                                id: response.retorno.id
                            }
                        });
                    }

                    this.$emit("salvou", response.retorno);
                });
        },
        excluir() {
            this.blExcluindo = true;

            this.$root.$api
                .delete("conteudo/excluir/" + this.obConteudo.id)
                .then(response => {
                    this.blDialogExcluir = false;

                    this.$router.push({
                        name: "admin-conteudo"
                    });
                });
        }
    }
};
