import { required, requiredIf } from "vuelidate/lib/validators";

export default {
    name: "select-assunto-tema",
    props: {
        obForm: Object,
        blRequired: Boolean
    },
    data() {
        return {
            arAssuntos: []
        };
    },
    validations: {
        obForm: {
            id_assunto: {
                required: requiredIf(function(value) {
                    return this.$props.blRequired || false;
                })
            },
            id_tema: {
                required: requiredIf(function(value) {
                    return this.$props.blRequired || false;
                })
            }
        }
    },
    created() {
        this.listarAssuntos();
    },
    methods: {
        listarAssuntos() {
            this.$root.$api.get("assunto/listar").then(response => {
                this.arAssuntos = response.retorno;
            });
        },
        changeAssunto() {
            this.$props.obForm.id_tema = null;
        },
        mensagemErro(strCampo) {
            return this.$utils.handleErrors(strCampo, this);
        }
    },
    computed: {
        listaTemas() {
            if (!this.$props.obForm.id_assunto || this.arAssuntos.length == 0) {
                return [];
            }

            let indexAssunto = this.arAssuntos.findIndex(assunto => {
                return assunto.id == this.obForm.id_assunto;
            });

            return this.arAssuntos[indexAssunto].temas;
        }
    }
};
